<template>
  <div id="">
    <Header />
    <div class="h-24 mb-4"></div>
    <div class="container p-4">
      <div class="px-2">
        <div class="-mx-4 flex flex-wrap">
          <div
            class="w-1/4 mb-4 px-2"
            v-for="(item, index) in data"
            :key="index"
          >
            <router-link
              :to="{ name: 'Category', params: { id: item.slug } }"
              class="block border border-transparent"
            >
              <div class="relative overflow-hidden">
                <b-image
                  class="inline-block"
                  :rounded="false"
                  :src="item.image ? item.image.thumb : $default_image_thumb"
                  :alt="item.slug"
                  ratio="1by1"
                  lazy
                ></b-image>
                <figure class="absolute inset-0 flex" v-if="item.video_url">
                  <!-- @mouseenter="playVideo" -->
                  <!-- @mouseleave="pauseVideo" -->
                  <video
                    class="object-cover invisible"
                    :ref="item.slug"
                    preload="none"
                    muted
                    loop
                    @loadeddata="onVideoReady"
                  >
                    <source :src="item.video_url" type="video/mp4" />
                  </video>
                </figure>
              </div>
              <div class="has-text-centered my-4 is-size-5 has-text-grey">
                {{ item.name }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/client/Header";
import store from "@/store";
export default {
  title: () => {
    return `Home - ${process.env.VUE_APP_TITLE}`;
  },
  data: () => ({
    //
  }),
  components: {
    Header
  },
  computed: {
    data() {
      return this.$store.state.client.data;
    }
  },
  methods: {
    onVideoReady(e) {
      if (e.target.readyState >= 3) {
        let el = e.target;
        el.classList.remove("invisible");
        el.play();
      }
    },
    playVideo(e) {
      e.target.children[0].play();
    },
    pauseVideo(e) {
      e.target.children[0].pause();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (
      store.getters.activeUser.is_client &&
      _.isNull(JSON.parse(localStorage.getItem("cart")))
    ) {
      next({ name: "Continue As" });
    } else {
      store.dispatch(to.meta.dispatch).then(() => {
        next(() => {
          store.commit("setBreadcrumbs", {
            name: to.name,
            label: to.name,
            index: 0
          });
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#scrollable {
  max-height: 100vh;
  overflow: scroll;
}
</style>
